/*Home parallax section*/
.parallax {
    perspective: 10px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #8b423b;
}

.parallax__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
}

.parallax-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d;
    z-index: 0; 
}

.parallax__0,
.parallax__1,
.parallax__2,
.parallax__3 {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    bottom: 0;
}

.parallax__0 {
    transform: translateZ(-25px) scale(3.5);
}

.parallax__1 {
    transform: translateZ(-20px) scale(3);
}

.parallax__2 {
    transform: translateZ(-10px) scale(2);
}

.parallax__3 {
    transform: translateZ(0) scale(1);
}

.potato{
    position: sticky;
    z-index: 1;
    width: 100vw;
    height: auto;
    transform: translateZ(0) scale(1);
}
