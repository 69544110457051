.modal-loader-container{
    background: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-body{
        width: 600px;
        height: 300px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        background: #1A252D;
        color:#ebf7ff;
        *{
            margin: auto
        }
        .btn-continue{
            padding: 15px 25px;
            background: #EB743C !important;
            color: white !important;
            font-size: 16px;
            line-height: 16px;
            border-radius: 5px;
            cursor: pointer;
            font-weight: 600;
            transition: all 200ms ease-in-out;
            &:disabled{
                background: #4a4172;
                color: #1A252D;
            }
        }
    }
}

/*CSS Loader*/
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  