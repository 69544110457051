@use "sass:math";

/*Color Palette*/
$color-primary: #ebf7ff;
$color-secondary: #EB743C;
$scroll-primary: #1A252D;
$scroll-secondary: #4a4172;
$color-background-v: #1A252D;
$font-primary: #ebf7ff;
$font-accent: #EB743C;
$font-accent-2: #8b423b;
$font-accent-3: #5a2b27;
/*transitions*/
$ease-fast: 150ms ease;
$ease-mid: 400ms ease;
$ease-slow: 600ms ease;

/*Fonts*/ 
@font-face {
  font-family: rust-title;
  src: url(./media/Fonts/Rust.ttf);
}
@font-face {
  font-family: roboto;
  src: url(./media/Fonts/Roboto-Regular.ttf);
} 
@font-face {
  font-family: roboto-thin;
  src: url(./media/Fonts/Roboto-Thin.ttf);
}
html,
body,
#root{
  overflow: hidden;
  height: auto;
  width: 100vw;
  background: #000000;
}
html, body, body > div, .App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*Scrollbars*/ 
.parallax::-webkit-scrollbar{
  width: 0.5rem;
  height: 0.5rem;
  background: $color-background-v;
  z-index: 15;
}

.parallax::-webkit-scrollbar-track{     
  background: rgba($color:  $scroll-primary, $alpha: 1); 
  z-index: 15;
}

.parallax::-webkit-scrollbar-thumb { 
  background: rgba($color:  $scroll-secondary, $alpha: 0.5); 
  z-index: 15;
}

.leaderboard::-webkit-scrollbar{
  width: 0.5rem;
  height: 0.5rem;
  background: #000000;
  z-index: 15;
}

.leaderboard::-webkit-scrollbar-track{     
  background: rgba($color:  $scroll-primary, $alpha: 0.7); 
  z-index: 15;
}

.leaderboard::-webkit-scrollbar-thumb { 
  background: rgba($color:  $font-accent-2, $alpha: 0.5); 
  z-index: 15;
}

/*Navigation*/ 
.sideNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
  color: $font-primary; 
  height: 1vh;
  z-index: 1;
  transition: all 0.2s ease-in-out 0s;
  border-bottom: 4em solid transparent; 
} 

.visible-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #EB743C;
  color: #ffffff;
  height: 1vh;
  z-index: 1;
  border-bottom: 4em solid transparent;
  border-image: url(./media/LineBar.svg);
  border-image-repeat: revert;
  border-image-slice: 100;
  border-image-outset: 1.55em;
} 

.sideNav-navigation {
  display: flex;
  align-items: center;
  height: 8vh;
  padding: 0 1rem;
} 
.sideNav-navigation-items {
    width: 100vw;   
    margin: 0;
    padding: 0;
  ul{ 
    display: flex;  
    width: 100vw;
    .desc{ 
      margin-left: auto;
      margin-right: 5vw;
      span{
        text-decoration: underline;
      }
    }
  }
  li {
    display: inline-block;
    list-style: none;
    font-size: 2.5vh;
    padding: 0 0.5rem;
    text-decoration: none !important;     
    margin: auto 10px; 
    cursor: pointer;
  }   
  a {
  color: inherit;
  text-decoration: none;
  font-size: 2.5vh;
  transition: all 400ms ease;
  font-family: rust-title;
  font-weight: 200;
}
} 

.disc-pup{
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 100px;
  z-index: 3;
  border-radius: 25% 25% 25% 25%;
  background: url(./media/tabs/disc.png);
  background-repeat: no-repeat;
  background-size: contain; 
  cursor: pointer; 
}

.discord{
  width: 350px;
  height: auto; 
  position: absolute;
  right: -350px;
  top:100px;
  transition: all $ease-fast;
  z-index: 4;
  .closeTab{ 
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    top:10px;
    right: 25px
  }
}

.show{  
  right: 0px !important;
  transition: all $ease-fast; 
}
 
.cal-pup{
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 150px;
  z-index: 3;
  border-radius: 25% 25% 25% 25%;
  background: url(./media/tabs/calendar.png);
  background-repeat: no-repeat;
  background-size: contain; 
  cursor: pointer; 
}

.calendar{
  width: 350px;
  height: auto;
  background: #FFFFFF;
  position: absolute;
  right: -350px;
  top:100px;
  transition: all $ease-fast;
  z-index: 4;
  .closeTab{ 
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 2rem;
    font-weight: bold;
  }
}


.parallax__cover{
  position: sticky;
  background: #2D112B;
  height: auto; 
  .welcome{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom, #28140f, #000000);
    color: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .title-section{
      font-family: rust-title;
      $width: 38%;
      width: $width;
      height: ($width * 1.61);
      background: url('./media/Adornments/RustAdornmentDome.svg') no-repeat center center;
      background-size: contain; 
      display: flex;
      align-items: center;
      justify-content: center; 
      .title{ 
        font-size: 2.5vw; 
        width: 100%;
      }
      .subtitle{    
        font-size: 2vw;
        width: 100%;
      }
    }
    .info{ 
      width: 58%;
      text-align: left;
      font-size: 1vw;
    } 
  }
}
/*info sections*/
  .Rules{ 
    width: 100vw;
    height: auto;
    background-image: linear-gradient(to bottom, #000000, #28140f);
    color: #ebf7ff;
    text-align: left;
    padding-bottom: 50px;
    position: relative;
    font-size: 1em; 
    h1{
      margin-top:0px;
      font-family: rust-title;
      text-align: center;
      font-size: 3vw;
      padding-bottom: 25px;
      padding-top:25px; 
    }
    .rules_img{
      &.img_1{ 
        background: url('./media/Adornments/RustAdornment1.svg') no-repeat center center;
        background-size: contain; 
        width: 30vw;
        height: 30vw;
        position: absolute;
        right: -5vw; 
        top: 120px;
        transform: scaleX(-1)
      }
      &.img_2{
        background: url('./media/Adornments/RustAdornmentRecycler.svg') no-repeat center center;
        background-size: contain; 
        width: 25vw;
        height: 25vw;
        position: absolute;
        left: -5vw; 
        top: 30%;
        
      } 
      &.img_3{ 
        background: url('./media/Adornments/RustAdornmentHorse.svg') no-repeat center center;
        background-size: contain; 
        width: 25vw;
        height: 25vw;
        position: absolute;
        right: -5vw; 
        bottom: 30%;
      }
      &.img_4{ 
        background: url('./media/Adornments/RustAdornmentWindmill.svg') no-repeat center center;
        background-size: contain; 
        width: 25vw;
        height: 25vw;
        position: absolute;
        left: -5vw; 
        bottom: 50px;
      }
    }

    .rules-data{
      height: auto;
      width: 60%;
      margin:auto; 
      z-index: 1;
      position: relative;
      font-size: 1vw; 
    }

  } 

  .Metrics{  
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom, #28140f, #000000);
    color: $font-primary;
    h1{
      font-family: rust-title;
      text-align: left;
      font-size: 3vw; 
      color: $color-primary; 
      margin-top: 0;
      padding-top: 100px;
      width: 100%;
      text-align: center;
    }
    .insights{ 
      width: 100%; 
      height: 70%;
      color: $font-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      overflow: hidden;
      .server-info{ 
        font-family: roboto;  
        width: 40%; 
        height: 100%;
        text-align: left; 
        margin: auto;
        background: rgba($color: $color-background-v, $alpha: 0.5);
        .server-img{
          width: 95%;
          margin: auto;
          height: fit-content;
          padding-top: 25px;
          img{
            width: 100%;
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
          }
        }
        .title{
          width: 100%;
          font-size: 1.5vw;
          text-align: center;
          padding-bottom: 5px;
        }
        .info{
          width: 100%;
          font-size: 1.25vw;
          text-align: center;
        } 
      }
      .leaderboard{
        width: 58%;
        text-align: left;
        font-size: 1vw;
        height: 100%;
        overflow-y: scroll;
        overflow-x:hidden; 
        table{
          margin: auto;
          width: 100%; 
          th{ 
            font-family: rust-title;
            text-align: left;
            font-size: 1.25em; 
            color: $font-accent;
          }
          tr>th:nth-child(1),
          tr>td:nth-child(1){
            text-align: center;
          }
          tr>th:nth-child(3),
          tr>td:nth-child(3){
            text-align: left;
          }
        }
      }
    }
  }

  .Donations{ 
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(to bottom, #000000, #28140f);
    color: $font-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    h1{
      font-family: rust-title;
      text-align: left;
      font-size: 3vw; 
      color: $color-primary; 
      margin-top: 0;
      padding-top: 100px;
      width: 100%;
      text-align: center;
    }
    .title-section{
      font-family: rust-title;
      $width: 30%;
      width: $width;
      height: ($width * 1.61);
      //background: url('./media/Adornments/RustAdornmentDome.svg') no-repeat center center;
      background-size: contain; 
      display: flex;
      align-items: center;
      justify-content: center; 
      .title{ 
        font-size: 2.5vw; 
        padding: 10px;
        border: 2px solid $font-accent-2;
        background: $font-accent-2;
        border-radius: 25px;
        cursor: pointer;
        transition: all $ease-mid;
        &:hover{
          background: $font-accent-3;
        }
      }
      .subtitle{    
        font-size: 2vw;
        width: 100%;
      }
    }
    .thanks-txt{ 
      width: 50%;
      text-align: center;
      font-size: 1vw;
    }
  }

  .Powered{
    width: 100%; 
    text-align: right;
    background: $font-accent-2;
    position: relative; 
    padding: 15px 0px;
    p{
      color: $color-primary;
      width: 90%;
      margin: auto;
      a{
        color: $font-accent;
        text-decoration: none; 
      }
    }
  }

  /*Mobile handling*/
  @media only screen and (max-width: 768px) {  

    /*Discord and calendar pop ups*/
    .disc-pup{
      top: 10px;
    }
    .cal-pup{
      top: 60px;
    }

    .discord{ 
      .closeTab{ 
        cursor: pointer;
        font-size: 2rem;
        font-weight: bold;
        color: #FFFFFF;
        position: absolute;
        top:10px;
        right: 25px
      }
    }

    .show{  
      right: 0px !important;
      transition: all $ease-fast; 
    }
    

    .calendar{  
      .closeTab{ 
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 2rem;
        font-weight: bold;
      }
    } 

    .sideNav{
      display: none;
    }


    /*Mobile rules*/
    .Rules{
      margin-top: 25vh; 
      position: relative; 
      h1{
        margin-top:0px;
        font-family: rust-title;
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 5px;
        padding-top: 50px; 
      }
      .rules_img{
        &.img_1,
        &.img_2,
        &.img_3,
        &.img_4{ display: none;
        } 
      }

      .rules-data{
        height: auto;
        width: 80%;
        margin:auto; 
        z-index: 1;
        position: relative;
        font-size: 0.75rem;
        .title{ 
          font-family: rust-title;
          text-align: center;
          font-size: 1.5rem;
          padding-bottom: 25px;
          padding-top:25px;
          color: $font-accent;
        }
        .info{ 
          font-family: roboto; 
          font-size: 1em; 
          padding-bottom: 25px;
        }
        li{
          padding-left: 25px;
        }
        .important{
          text-decoration: underline;
          color: $font-accent-2;
          font-size: 1.15rem; 
          font-weight: bold;
          text-align: center;
        }
        table{
          width: 80%;
          font-weight: bold;
          padding-left: 25px;
          tbody>tr>td{  
            width: 50%;
            color: $font-accent-2;
            text-align: center;
          }
        }
      }

    } 
  
    .Metrics{  
      width: 100%;
      height: auto;
      background-image: linear-gradient(to bottom, #28140f, #000000);
      color: $font-primary;
      h1{
        margin-top:0px;
        font-family: rust-title;
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 5px;
        padding-top: 50px; 
      }
      .insights{ 
        width: 100%; 
        height: 100vh;
        color: $font-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
        .server-info{ 
          font-family: roboto;  
          width: 90%; 
          height: auto; 
          margin: auto;
          background: rgba($color: $color-background-v, $alpha: 0.5);
          padding-bottom: 25px;
          .server-img{
            width: 95%;
            margin: auto;
            height: fit-content;
            padding-top: 25px;
            img{
              width: 100%;
              mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
            }
          }
          .title{
            width: 100%;
            font-size: 1.5rem;
            text-align: center;
            padding-bottom: 5px;
          }
          .info{
            width: 100%;
            font-size: 0.75rem;
            text-align: center;
          } 
        }

        .leaderboard{
          width: 90%;
          text-align: left;
          font-size: 1rem;
          height: 50%;
          overflow-y: scroll;
          overflow-x:hidden; 
          margin-top: 25px;
          table{
            margin: auto;
            width: 100%; 
            th{ 
              font-family: rust-title;
              text-align: center;
              font-size: 1.25rem; 
              color: $font-accent;
            }
            tr>th:nth-child(1),
            tr>td:nth-child(1){
              text-align: center;
            }
            tr>th:nth-child(3),
            tr>td:nth-child(3){
              text-align: left;
            }
          }
        }
      }
    } 

    .Donations{  
      height: auto;
      h1{
        margin-top:0px;
        font-family: rust-title;
        text-align: center;
        font-size: 1.5rem;
        padding-bottom: 5px;
        padding-top: 50px; 
      }
      .title-section{
        font-family: rust-title;
        width: 100%; 
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;  
        }  
      .thanks-txt{ 
        width: 90%;
        text-align: center;
        font-size: 1rem;
      }
    }

    .Powered{
      text-align: center;
    }
  }